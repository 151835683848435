import React, { Fragment } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import midaniLogoArabic from 'assets/images/midani-logo-arabic.svg';
import midaniLogoEnglish from 'assets/images/midani-logo-english.svg';
import promoImg from 'assets/images/promo.png';
import { isRTL } from 'Language';

const NonAuthenticatedLayout = () => {
  const location = useLocation();
  const isLogin = false;

  return (
    <Fragment>
      <div className='non-auth-layout'>
        <div className='left'>
          <img src={isRTL() ? midaniLogoArabic : midaniLogoEnglish} className='logo' alt='promo' />
          <img src={promoImg} className='promo' alt='promo' />
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
        <div className='right'>
          {!isLogin ? <Outlet /> : <Navigate to='/home' replace state={{ from: location }} />}
        </div>
      </div>
    </Fragment>
  );
};

export default NonAuthenticatedLayout;
