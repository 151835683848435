import Error from 'components/Error';
import Loader from 'components/common-components/Loader';
import TabBody from 'components/tab-components/TabBody';
import TabHeader from 'components/tab-components/TabHeader';
import TabLayout from 'components/tab-components/TabLayout';
import React, { useEffect, useState } from 'react';
import CompaniesListHeader from './CompaniesListHeader';
import TableComponent from 'components/table-component/Table';
import { CompaniesTableFields, CompaniesTabsList } from './utils';
import { CallAPI } from 'actions/General';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const UsersList = () => {
  const [selectedTab, setSelectedTab] = useState(CompaniesTabsList[0]);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState('');
  const [tableHeader] = useState(CompaniesTableFields);
  const [companies, setCompanies] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteringData, setFilteringData] = useState({});
  const [filteringDataState, setFilteringDataState] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [isActionAllowed, setIsActionAllowed] = useState(true);
  const { t } = useTranslation();

  const getAllCompanies = async (page, limit) => {
    let apiPageNo = pageNo;
    let apiLimit = fetchLimit;
    if (page) {
      setPageNo(page);
      setFetchLimit(limit);
      apiPageNo = page;
      apiLimit = limit;
    }
    let callData = { page: apiPageNo, limit: apiLimit };
    if (filteringData.search) callData.searchText = filteringData.search;
    let result = await CallAPI('GET_COMPANIES_LIST', callData, null, setLoader, setApiError, null);
    if (result.status) {
      setCompanies(result.data.companies);
      if (result.data.totalCount) setTotalRecords(result.data.totalCount);
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, [filteringData]);
  const onRowsSelection = (name, value) => {
    let tempSelectedRows = cloneDeep(selectedRows);
    if (name === 'all') {
      if (value)
        companies.map((single_company) => {
          if (value && tempSelectedRows.indexOf(single_company._id) === -1)
            tempSelectedRows.push(single_company._id);
        });
      else if (!value) tempSelectedRows = [];
    } else {
      if (value) {
        tempSelectedRows.push(name);
      } else tempSelectedRows = tempSelectedRows.filter((selectedId) => selectedId !== name);
    }
    setSelectedRows(tempSelectedRows);
    console.log(tempSelectedRows);
    let isActionAllowed = true;
    for (let index = 0; index < companies.length; index++) {
      const single_company = companies[index];
      if (tempSelectedRows.indexOf(single_company._id) > -1) {
        console.log('in', single_company.companyStatus);
        if (single_company.companyStatus === 'protected') {
          console.log('if');
          isActionAllowed = false;
        }
      }
    }
    console.log('isActionAllowed', isActionAllowed);
    setIsActionAllowed(isActionAllowed);
  };

  const searchChangedViaDebounce = (name, value) => {
    setFilteringData(cloneDeep({ ...filteringData, [name]: value }));
  };
  const updateFilteringData = (name, value) => {
    setFilteringDataState({ ...filteringDataState, [name]: value });
  };
  const translateValueFunc = (val) => {
    return t(val);
  };
  return (
    <TabLayout>
      <TabHeader
        tabList={CompaniesTabsList}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        fixedWidth
      />
      <TabBody>
        {loader && <Loader color='green-1' type='modal-spinner' />}
        {apiError ? <Error msg={apiError} /> : <></>}
        <CompaniesListHeader
          selectedTab={selectedTab.value}
          selectedRows={selectedRows}
          getAllCompanies={getAllCompanies}
          debounceFunc={searchChangedViaDebounce}
          filteringData={filteringDataState}
          setFilteringData={updateFilteringData}
          isActionAllowed={isActionAllowed}
        />
        <TableComponent
          header={tableHeader}
          data={companies}
          props={{
            actionsCallBack: getAllCompanies,
            translateValueFunc: translateValueFunc,
            actionsType: selectedTab.value,
          }}
          totalRecords={totalRecords}
          loader={loader}
          isFieldSelectionEnabled={false}
          isPaginationEnabled={true}
          isActionsEnabled={true}
          isSelectionEnabled={true}
          selectionKey={'_id'}
          paginationFunction={getAllCompanies}
          onSelection={onRowsSelection}
          selectedRows={selectedRows}
        />
      </TabBody>
    </TabLayout>
  );
};

export default UsersList;
