import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FormLoadingButton = ({
  text,
  variant,
  icon,
  classes,
  iconClasses,
  onClick,
  loading,
  disabled,
  shrink,
  iconRight,
}) => {
  const { t } = useTranslation();
  const baseClass = `theme-button-${variant}`;

  const shrinkButton = shrink ? 'shrink-button' : '';
  return (
    <div
      className={`custom-form-button ${shrinkButton} ${baseClass}${
        loading ? ' ' + baseClass + '-loading' : ''
      }${disabled ? ' ' + baseClass + '-disabled' : ''} ${classes ?? ''}`}
      {...(!disabled && onClick && !loading && { onClick: (e) => onClick(e) })}
    >
      {!iconRight && (
        <Fragment>{icon && <img src={icon} className={iconClasses ?? ''} />}</Fragment>
      )}
      {t(text)}
      {iconRight && <Fragment>{icon && <img src={icon} className={iconClasses ?? ''} />}</Fragment>}
      {loading && (
        <Spinner animation='border' variant='secondary' className={`${baseClass}-spinner`} />
      )}
    </div>
  );
};

FormLoadingButton.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  icon: PropTypes.string,
  classes: PropTypes.string,
  iconClasses: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  shrink: PropTypes.bool,
  iconRight: PropTypes.bool,
};

export default FormLoadingButton;
