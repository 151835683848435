import React, { Fragment } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const AuthenticatedLayout = () => {
  const location = useLocation();
  const isLogin = true;

  return (
    <Fragment>
      <Header />
      <Sidebar />
      <div className='main-layout'>
        <div className='main-layout-container'>
          {isLogin ? <Outlet /> : <Navigate to='/login' replace state={{ from: location }} />}
        </div>
      </div>
    </Fragment>
  );
};

export default AuthenticatedLayout;
