import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableData from './TableData';
import Pagination from 'components/common-components/Pagination';
import MoreFieldsDropdown from 'components/common-components/MoreFieldsDropdown';

const TableComponent = ({
  header,
  data,
  totalRecords,
  loader,
  props,
  isFieldSelectionEnabled,
  isPaginationEnabled,
  isActionsEnabled,
  isSelectionEnabled,
  selectionKey,
  maintainTableHeight = true,
  paginationFunction,
  onSelection,
  selectedRows,
}) => {
  let { type, setTableHeaderChange, filteringHeader, actionsType, resetHeader } = { ...props };
  const [tableHeaderTesting, setTableHeaderTesting] = useState([]);
  const [displayEntries, setDisplayEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setTableHeaderTesting(header);
  }, [header]);

  const changeDisplayEntries = (name, val) => {
    setDisplayEntries(parseInt(val));
    paginationFunction(1, parseInt(val));
  };

  return (
    <div className='d-flex'>
      <div
        className={`${type !== 'submissions' ? 'flex-grow-1 ' : ''}overflow-auto position-relative`}
      >
        {isFieldSelectionEnabled && (
          <div className={`position-absolute top-0 end-0`}>
            <MoreFieldsDropdown
              fieldList={filteringHeader ? filteringHeader : tableHeaderTesting}
              setFieldListSelection={
                setTableHeaderChange ? setTableHeaderChange : setTableHeaderTesting
              }
              actionsType={actionsType}
              resetHeader={resetHeader}
            />
          </div>
        )}
        <div className={`table-responsive ${maintainTableHeight ? 'maintain-height' : ''}`}>
          <table
            className={` table-hover table-custom ${type === 'form_permissions' ? 'inverse' : ''} `}
          >
            <TableHeader
              tableFields={tableHeaderTesting}
              onSelection={onSelection}
              isSelectionEnabled={isSelectionEnabled}
              isActionsEnabled={isActionsEnabled}
              selectionValue={
                selectedRows && selectedRows.length > 0
                  ? selectedRows.length === data.length
                  : false
              }
              type={type}
            />
            <TableData
              onSelection={onSelection}
              data={data}
              tableFields={tableHeaderTesting}
              loader={loader}
              props={props}
              isSelectionEnabled={isSelectionEnabled}
              isActionsEnabled={isActionsEnabled}
              selectionKey={selectionKey}
              selectedRows={selectedRows}
            />
          </table>
        </div>
        {isPaginationEnabled && (
          <Pagination
            totalRecords={totalRecords}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            displayEntries={displayEntries}
            setDisplayEntries={changeDisplayEntries}
            currentPageRecords={data ? data.length : 0}
            paginationFunction={paginationFunction}
          />
        )}
      </div>
    </div>
  );
};
TableComponent.propTypes = {
  selectedRows: PropTypes.array,
  header: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
  loader: PropTypes.bool.isRequired,
  props: PropTypes.object.isRequired,
  isFieldSelectionEnabled: PropTypes.bool.isRequired,
  isPaginationEnabled: PropTypes.bool.isRequired,
  isActionsEnabled: PropTypes.bool.isRequired,
  isSelectionEnabled: PropTypes.bool,
  selectionKey: PropTypes.string,
  maintainTableHeight: PropTypes.bool,
  paginationFunction: PropTypes.func,
  onSelection: PropTypes.func,
};
export default TableComponent;
