import companiesIcon from 'assets/images/icons/companies.svg';
export const CompaniesTabsList = [
  {
    id: 1,
    title: 'tabs_companies',
    value: 'companies',
    breadcrumbValue: { icon: companiesIcon, items: ['tabs_super_admin', 'tabs_companies'] },
  },
];
export const CompaniesTableFields = [
  {
    name: 'companyName',
    displayName: 'table_header_company_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    mandatory: true,
    associatedKey: 'companyName',
  },
  {
    name: 'companyNumber',
    displayName: 'table_header_company_number',
    type: 'string',
    mandatory: true,
    isRequired: true,
    isSelected: true,
    associatedKey: 'companyCode',
  },
  {
    name: 'owner',
    displayName: 'table_header_owner_name',
    type: 'string',
    isRequired: true,
    isSelected: true,
    associatedKey: 'companyCreatedByUser',
    populateFunc: 'getCompanyOwnerName',
  },
  {
    name: 'users',
    displayName: 'table_header_companies_users',
    isSelected: true,
    associatedKey: 'totalUsersCount',
  },
  {
    name: 'form_submissions',
    displayName: 'table_header_form_submitted',
    type: 'number',
    isRequired: true,
    isSelected: true,
    associatedKey: 'totalSubmissionsCount',
  },
  {
    name: 'status',
    displayName: 'table_header_company_status',
    type: 'text',
    isRequired: false,
    isSelected: true,
    translateVal: true,
    associatedKey: 'companyStatus',
    populateFunc: 'populateStatus',
  },
  {
    name: 'locked',
    displayName: 'table_header_company_locked',
    type: 'text',
    isRequired: false,
    isSelected: true,
    associatedKey: 'companyStatus',
    populateFunc: 'populateLockStatusIcon',
  },
];
