import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Error = ({ msg, type = null, classList = '' }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`alert alert-${type === null ? 'danger' : 'success'} ${classList}`}
      role='alert'
    >
      {t(msg)}
    </div>
  );
};
Error.propTypes = {
  msg: PropTypes.string.isRequired,
  type: PropTypes.string,
  classList: PropTypes.string,
};
export default Error;
