import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const Loader = ({ animation, variant, color, type }) => {
  const loaderClasses = color ? `loader-${color}` : '';
  return (
    <Fragment>
      <div className={`${type ? type : ''}`}>
        <Spinner animation={animation} variant={variant} className={loaderClasses} />
      </div>
    </Fragment>
  );
};

Loader.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string.isRequired,
};

Loader.defaultProps = {
  animation: 'border',
  variant: 'secondary',
};

export default Loader;
