import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AuthReducer } from '../../redux/auth';
import { cloneDeep } from 'lodash';

const AuthAction = AuthReducer.actions;

const TabHeader = ({
  tabList,
  selectedTab,
  setSelectedTab,
  disbaledId,
  variant,
  fixedWidth,
  tabUserName,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTab.breadcrumbValue) {
      let breadCrumbs = cloneDeep(selectedTab.breadcrumbValue);
      let breadCrumbsItems = [];
      breadCrumbs.items.map((singleItem) => {
        if (singleItem === '%%%User Name%%%') breadCrumbsItems.push(tabUserName);
        else breadCrumbsItems.push(singleItem);
      });
      breadCrumbs.items = breadCrumbsItems;
      dispatch(AuthAction.updateBreadCrumb({ breadcrumbs: breadCrumbs }));
    }
  }, [selectedTab]);

  return (
    <div className={`tab-layout-header ${variant ?? ''}`}>
      {tabList.map((tab, index) => (
        <div
          key={index}
          className={`tab-item${selectedTab.id === tab.id ? ' active' : ''} ${
            disbaledId && disbaledId.indexOf(tab.id) > -1 ? 'disabled' : ''
          }${fixedWidth && 'fixed'}`}
          onClick={() => {
            disbaledId && disbaledId.indexOf(tab.id) > -1 ? null : setSelectedTab(tab);
          }}
        >
          <img src={selectedTab.id === tab.id ? tab.activeIcon : tab.icon} />
          {t(tab.title)}
        </div>
      ))}
    </div>
  );
};

TabHeader.propTypes = {
  tabList: PropTypes.array.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  disbaledId: PropTypes.array,
  variant: PropTypes.string,
  fixedWidth: PropTypes.bool,
  tabUserName: PropTypes.string,
};

export default TabHeader;
