import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const TextField = ({
  name,
  placeholder,
  displayName,
  hintText,
  icon,
  value,
  error,
  touched,
  shrink,
  shrinkFieldOnly,
  handleChange,
  classes,
  isHorizontal,
  horizontalLabelClass,
  disabled,
  isDisplayAbleOnly,
  debounceFunc,
}) => {
  const [debouncedCallApi] = useState(debounceFunc ? () => debounce(debounceFunc, 1000) : []);
  const { t } = useTranslation();
  const shrinkField = shrink || shrinkFieldOnly ? ' shrink-field' : '';
  const shrinkLabel = shrink ? ' shrink-label' : '';
  const shrinkHint = shrink ? 'theme-size-0_7' : 'theme-size-0_8';

  return (
    <div
      className={`custom-input-box${classes ? ' ' + classes : ''} ${
        isHorizontal ? 'd-flex align-items-center flex-column w-100' : ''
      } ${isDisplayAbleOnly ? 'read-only' : ''}`}
    >
      <div className={`${isHorizontal ? 'd-flex w-100 align-items-center' : ''}`}>
        <div
          className={`d-flex ${
            isHorizontal ? horizontalLabelClass + ' flex-column' : 'gap-2 align-items-baseline'
          }`}
        >
          {displayName && (
            <label
              htmlFor={name}
              className={`${!touched && error ? 'label-error' + shrinkLabel : shrinkLabel}`}
            >
              {t(displayName)}
            </label>
          )}
          {hintText && (
            <span htmlFor={name} className={shrinkHint}>
              {t(hintText)}
            </span>
          )}
        </div>

        <div className={`custom-input-field ${isHorizontal ? 'w-100' : ''}`}>
          <input
            disabled={disabled}
            type='text'
            id={name}
            name={name}
            className={`${!touched && error ? 'input-error' + shrinkField : shrinkField}`}
            placeholder={t(placeholder)}
            value={value}
            autoComplete='off'
            onChange={(e) => {
              if (!isDisplayAbleOnly) {
                handleChange(e.target.name, e.target.value);
                if (debounceFunc) {
                  debouncedCallApi(e.target.name, e.target.value);
                }
              }
            }}
          />
          {icon && <img src={icon} className='icon' />}
        </div>
      </div>
      <div className={`${isHorizontal ? 'd-flex w-100' : ''}`}>
        {!touched && error && (
          <>
            {displayName && isHorizontal && (
              <label
                htmlFor={name}
                className={`${!touched && error ? 'label-error' + shrinkLabel : shrinkLabel} ${
                  isHorizontal ? horizontalLabelClass : ''
                }`}
              ></label>
            )}
            <p className={`form-field-error ${isHorizontal ? 'w-100' : ''}`}>{t('error '+error)}</p>
          </>
        )}
      </div>
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  hintText: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  shrink: PropTypes.bool,
  shrinkFieldOnly: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  horizontalLabelClass: PropTypes.string,
  isDisplayAbleOnly: PropTypes.bool,
  debounceFunc: PropTypes.func,
};

export default TextField;
