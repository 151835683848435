import axios from 'axios';
import APIRoutes from './APIRoutes.json';
import APIMessages from './APIMessages.json';
import * as ReduxActions from './ReduxActions';

const CallAPI = async (type, data, id, setLoader, setApiError, history = null) => {
  let APISingleObject = APIRoutes[type];
  if (setLoader) setLoader(true);
  if (setApiError) setApiError('');
  let result = await SendCallToServer(data, id, APISingleObject);
  if (result.error) {
    if (setApiError)
      setApiError(APIMessages[result.error] ? APIMessages[result.error] : result.error);
    if (setLoader) setLoader(false);
    return {
      status: 0,
      error: APIMessages[result.error] ? APIMessages[result.error] : result.error,
    };
  } else {
    let returnedData = result.data;
    if (APISingleObject.callback)
      returnedData = await ReduxActions[APISingleObject.callback](result.data, history);
    if (setLoader) setLoader(false);
    return { status: 1, data: returnedData };
  }
};
const SendCallToServer = (data, id, APISingleObject) => {
  let url = APISingleObject.url;
  if (id) url += id;
  let method = APISingleObject.method;
  axios.defaults.headers.common['token_type'] = APISingleObject.token_type
    ? APISingleObject.token_type
    : '';
  return axios[method](url, data);
};
// const UpdateFireBaseToken = async () => {
//   return await firebase.auth.currentUser.getIdToken().then(function (data) {
//     dispatch(AuthAction.updateAuthState({ firebaseToken: data }));
//     return { status: 1 };
//   });
// };
export { CallAPI };
