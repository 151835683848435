import * as yup from 'yup';
import { AuthReducer } from '../../redux/auth';
import store from '../../store/store';
import { CallAPI } from 'actions/General';
import NodeRSA from 'node-rsa';
const AuthAction = AuthReducer.actions;
const dispatch = store.dispatch;

export const loginSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const userLogin = async (data, setLoader, setApiError) => {
  const result = await CallAPI(
    'LOGIN_API',
    { loginId: data.email, password: await encryptPassword(data.password) },
    null,
    setLoader,
    setApiError,
    null,
  );
  console.log('result', result);
  if (result.status && result.data.status === 'active') {
    dispatch(
      AuthAction.updateAuthState({
        accessToken: result.data.access_token,
        accessRefreshToken: result.data.refresh_token,
      }),
    );
  }
};

export const updateRefreshToken = async () => {
  const result = await CallAPI('REFRESH_AUTH_TOKEN', null, null, null, null, null);
  console.log('result', result);
  if (result.status) {
    dispatch(
      AuthAction.updateAuthState({
        accessToken: result.data.access_token,
        accessRefreshToken: result.data.refresh_token,
      }),
    );
  }
};

const encryptPassword = async (password) => {
  // decoding base64 public key
  // eslint-disable-next-line no-undef
  const SERVER_PUBLIC_KEY = atob(process.env.REACT_APP_SERVER_PUBLIC_KEY);
  try {
    const publicKey = new NodeRSA(SERVER_PUBLIC_KEY, 'pkcs1-public', {
      encryptionScheme: {
        hash: 'sha1',
      },
    });

    const encryptedData = publicKey.encrypt(password, 'base64');
    return encryptedData;
  } catch (err) {
    console.log('Encryption failed', err);
  }
};
