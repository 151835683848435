import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const FirebaseProtectedRoute = () => {
  const authState = useSelector((state) => state.auth);
  const location = useLocation();
  const isLogin = authState.accessToken;

  return (
    <Fragment>
      {isLogin ? <Outlet /> : <Navigate to='/login' replace state={{ from: location }} />}
    </Fragment>
  );
};

export default FirebaseProtectedRoute;
