import axios from 'axios';
import store from '../store/store';
import { AuthReducer } from '../redux/auth';
import { updateRefreshToken } from 'pages/auth-pages/utils';
const AuthActions = AuthReducer.actions;
axios.interceptors.request.use(
  function (config) {
    let apiPath = process.env.REACT_APP_API_PATH;
    // apiPath = 'http://192.168.0.187:3000/v1' //whizpool-new;
    // apiPath = 'http://192.168.0.125:3000/v1' // whizpool;
    // apiPath = 'http://localhost:3000/v1'; // localhost;
    let url = config.url;
    let auth = store.getState().auth;

    let isRetry = config._retry;

    if (!isRetry) {
      config.url = apiPath + '/' + url;
    }
    let tokenType = config.headers.token_type;
    if (!isRetry) {
      config.url = apiPath + '/' + url;
    } else {
      tokenType = config.headers['token-type'];
    }

    let authToken =
      tokenType === 'staticToken'
        ? process.env.REACT_APP_STATIC_TOKEN
        : tokenType === 'refresh'
        ? auth.accessRefreshToken
        : auth.accessToken;
    let headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + authToken,
    };
    config.headers = headers;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    // RETURN SUCCESS RESPONSE
    if (response.status === 200 || response.status === 201) {
      return { data: response.data.data, error: '' };
    } else {
      // RETURN ERROR RESPONSE
      return { data: {}, error: response.error.msg };
    }
  },
  async function (error) {
    if (error.response && error.response.status === 401) {
      let originalRequest = error.config;
      if (!originalRequest._retry) {
        console.log('Refresh');
        await updateRefreshToken();
        originalRequest._retry = true;
        return new Promise((resolve) => {
          resolve(axios(originalRequest));
        });
      } else {
        store.dispatch(
          AuthActions.updateAuthState({ accessToken: null, accessRefreshToken: null }),
        );
        window.location.href = '/login';
      }
    } else {
      return {
        data: {},
        // error: error.msg,
        error: error.response.data.error.msg ?? error.response.data.error,
      };
    }
    console.log('ERROR', error.response.data.error);
    return {
      data: {},
      error: error.response.data.error.msg ?? error.response.data.error,
      // error: 'error',
    };
    // return Promise.reject(error);
  },
);
