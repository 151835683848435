import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToUrl = (url) => {
    navigate(url);
  };
  return (
    <div className='sidebar'>
      <section key={1}>
        <ul>
          <li
            className={`${location.pathname.startsWith('/companies') ? 'active' : ''}`}
            onClick={() => navigateToUrl('companies')}
          >
            <img src={null} />
            <span>{t('Companies')}</span>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Sidebar;
