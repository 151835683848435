import CompaniesActions from 'pages/companies/CompaniesActions';
import LockedImg from 'assets/images/icons/lock-grey.svg';
import UnLockedImg from 'assets/images/icons/unlock-lock.svg';

const userInitials = (row) => {
  let initial = '';
  if (row.user && row.user.firstName) initial += row.user.firstName[0];
  if (row.user && row.user.lastName) initial += row.user.lastName[0];
  return <div className='profileImage text-uppercase'>{initial}</div>;
};

const populateDate = (date) => {
  return date ? new Date(date).toLocaleDateString('en-US') : '';
};
const populateTime = (date) => {
  return date ? new Date(date).toLocaleTimeString('en-US') : '';
};
const populateDateTime = (date) => {
  return date ? new Date(date).toLocaleString('en-US') : '';
};
const populateUnixToDate = (date) => {
  return date ? new Date(parseInt(date)).toLocaleDateString('en-US') : '';
};
const populateUnixToTime = (date) => {
  return date ? new Date(parseInt(date)).toLocaleTimeString('en-US') : '';
};
const populateUnixToDateTime = (date) => {
  return date ? new Date(parseInt(date)).toLocaleString('en-US') : '';
};
function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}

const getCompanyOwnerName = (user) => {
  return user ? user.firstName + ' ' + user.lastName : '';
};
const populateStatus = (data, callback, translateVal) => {
  const classification =
    data === 'active'
      ? { class: 'green-3', label: 'text_active' }
      : data === 'protected'
      ? { class: 'orange-1', label: 'text_protected' }
      : data === 'unprotected'
      ? { class: 'white-2', label: 'text_unprotected' }
      : data === 'suspended'
      ? { class: 'red-3', label: 'text_suspended' }
      : { class: 'orange-1', label: 'text_request_pending' };

  return (
    <p
      className={`mb-0 theme-button-${classification.class} rounded-full px-3 py-1 theme-size-1 width-max-content`}
    >
      {translateVal(classification.label)}
    </p>
  );
};
const populateLockStatusIcon = (data) => {
  return <img src={data === 'protected' ? LockedImg : UnLockedImg} />;
};
const actionsList = (row, type, actionsCallBack) => {
  return type === 'companies' ? (
    <CompaniesActions
      ids={[row._id]}
      actionData={{ isActionAllowed: row.companyStatus !== 'protected' }}
      isActionButton={true}
      actionsCallBack={actionsCallBack}
      type={'row_' + type}
    />
  ) : (
    ''
  );
};
export default {
  userInitials,
  populateStatus,
  populateDate,
  populateTime,
  populateDateTime,
  populateUnixToDate,
  populateUnixToTime,
  populateUnixToDateTime,
  timeSince,
  actionsList,
  getCompanyOwnerName,
  populateLockStatusIcon,
};
