import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { CallAPI } from 'actions/General';
import AlertModal from 'components/common-components/AlertModal';
import FormButton from 'components/form-components/FormButton';
import dotsIcon from 'assets/images/icons/table-icons.svg';
import dropdownIcon from 'assets/images/icons/dropdown-arrow-white.svg';

function CompaniesActions({ ids, isActionButton, type, actionsCallBack, actionData }) {
  const [displayedMenu, setDisplayedMenu] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeleteConfirmType, setShowDeleteConfirmType] = useState('');
  const [actionType, setActionType] = useState('');
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();

  const applyUserAction = async () => {
    let data = null;
    if (actionType !== 'delete')
      data = {
        companies: ids,
        companyStatus: actionType,
      };
    else data = { companies: ids };
    if (actionType !== 'delete')
      await CallAPI('UPDATE_COMPANY_STATUS', data, null, setLoader, null, null);
    else await CallAPI('DELETE_COMPANY_PERMANENTLY', data, null, setLoader, null, null);
    setShowDeleteConfirm(false);
    actionsCallBack();
  };
  const confirmDeletion = (type, action) => {
    setShowDeleteConfirm(true);
    setShowDeleteConfirmType(type);
    setActionType(action);
  };

  useEffect(() => {
    let companiesActionsList = [
      {
        isAllowed: true,
        name: 'action_activate',
        action: () => {
          confirmDeletion('update', 'active');
        },
      },
      {
        isAllowed: true,
        name: 'action_lock',
        action: () => {
          confirmDeletion('update', 'protected');
        },
      },
      {
        isAllowed: true,
        name: 'action_unlock',
        action: () => {
          confirmDeletion('update', 'unprotected');
        },
      },
      {
        isAllowed: actionData.isActionAllowed,
        name: 'action_suspend',
        action: () => {
          confirmDeletion('update', 'suspended');
        },
      },
      {
        isAllowed: actionData.isActionAllowed,
        name: 'action_delete',
        action: () => {
          confirmDeletion('delete', 'delete');
        },
      },
    ];

    setDisplayedMenu(companiesActionsList.filter((singleAction) => singleAction.isAllowed));
  }, [type, ids]);
  return (
    <div className='dropdown-actions'>
      {displayedMenu.length > 0 && (
        <DropdownButton
          disabled={ids.length > 0 ? false : true}
          as={ButtonGroup}
          drop='bottom'
          variant='secondary'
          title={
            <>
              {isActionButton ? (
                <img src={dotsIcon} alt='Loading...' />
              ) : (
                <FormButton text='button_action' variant='green-1' icon={dropdownIcon} />
              )}
            </>
          }
        >
          {displayedMenu.map((button, index) => (
            <Dropdown.Item
              onClick={button.action}
              key={index}
              className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
            >
              {t(button.name)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
      <AlertModal
        show={showDeleteConfirm}
        showCloseButton={true}
        closeModal={() => setShowDeleteConfirm(false)}
        variant='delete'
        message={
          showDeleteConfirmType === 'update' ? 'alert_update_status' : 'alert_delete_company'
        }
        actionButtons={[
          {
            text: t(showDeleteConfirmType === 'update' ? 'button_ok' : 'button_confirm'),
            variant: 'red-1',
            onClick: applyUserAction,
            loading: loader,
          },
          {
            text: t('button_cancel'),
            variant: 'white-1',
            onClick: () => setShowDeleteConfirm(false),
          },
        ]}
      />
    </div>
  );
}
CompaniesActions.propTypes = {
  ids: PropTypes.array.isRequired,
  actionData: PropTypes.object,
  type: PropTypes.string.isRequired,
  isActionButton: PropTypes.bool.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
};
export default CompaniesActions;
