import { createSlice } from '@reduxjs/toolkit';

export const AuthReducer = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    accessRefreshToken: null,
    breadcrumbs: { icon: null, items: [] },
    customizedTableHeader: {},
  },
  reducers: {
    updateAuthState: (state, action) => {
      let data = state;
      data = { ...data, ...action.payload };
      state.accessToken = data.accessToken;
      state.accessRefreshToken = data.accessRefreshToken;
    },
    updateBreadCrumb: (state, action) => {
      state.breadcrumbs = action.payload.breadcrumbs;
    },
    updateCustomizedTableHeader: (state, action) => {
      state.customizedTableHeader = {
        ...state.customizedTableHeader,
        [action.payload.type]: action.payload.customizedTableHeader,
      };
    },
  },
});
