import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n/config';

export const LanguageReducer = createSlice({
  name: 'language',
  initialState: { lang: i18n.language },
  reducers: {
    switchLanguage: (state, action) => {
      state = { lang: action.payload };
      return state;
    },
  },
});
