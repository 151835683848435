import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import { AuthReducer } from "../redux/auth";
import { LanguageReducer } from "../redux/language";

const rootPersistConfig = {
  key: "midani_admin_" + process.env.REACT_APP_ENVIRONMENT,
  storage: storage,
};

const reducers = combineReducers({
  auth: AuthReducer.reducer,
  language: LanguageReducer.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
