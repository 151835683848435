import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';

const TableHeader = ({
  tableFields,
  onSelection,
  isActionsEnabled,
  isSelectionEnabled,
  selectionValue,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        {isSelectionEnabled && (
          <th className='table-checkbox'>
            <SingleCheckBoxField
              name={'all'}
              value={selectionValue}
              handleChange={onSelection}
              text={<></>}
              classes=''
            />
          </th>
        )}
        {tableFields.map((singleHeaderField, index) => {
          return singleHeaderField.isSelected ? (
            <th key={index} className='nowrap'>
              <div
                className={`${
                  type === 'submissions' ? 'd-flex gap-3 justify-content-between' : ''
                }`}
              >
                <div>{t(singleHeaderField.displayName)}</div>
                {type === 'submissions' && <div className='border'></div>}
              </div>
            </th>
          ) : null;
        })}
        {isActionsEnabled && <th></th>}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  tableFields: PropTypes.array.isRequired,
  onSelection: PropTypes.func,
  isSelectionEnabled: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  selectionValue: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

export default TableHeader;
