import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelectField from 'components/form-components/SelectField';
import selectArrow from 'assets/images/select-arrow.png';
import { useTranslation } from 'react-i18next';
const Pagination = ({
  totalRecords,
  currentPage,
  setCurrentPage,
  displayEntries,
  currentPageRecords,
  paginationFunction,
  setDisplayEntries,
  type = 'general',
}) => {
  const [totalPages, setPages] = useState(0);

  useEffect(() => {
    setPages(Math.ceil(totalRecords / displayEntries));
  }, [totalRecords, displayEntries]);

  const { t } = useTranslation();
  return (
    <div className='table-pagination'>
      {type !== 'form' && (
        <div className='displayRecords'>
          {t('pagination_show')}
          <SelectField
            handleChange={setDisplayEntries}
            name='pagination'
            listValues={[
              {
                value: '10',
                displayValue: '10',
                isNested: false,
              },
              {
                value: '20',
                displayValue: '20',
                isNested: false,
              },
              {
                value: '50',
                displayValue: '50',
                isNested: false,
              },
              {
                value: '100',
                displayValue: '100',
                isNested: false,
              },
            ]}
            label={displayEntries.toString()}
            icon={selectArrow}
            isAutoClose={true}
            classes='d-flex align-items-center gap-1 border-grey-1-h1 px-3'
          />
          {t('pagination_entries')}
        </div>
      )}
      <div className='paginationList'>
        <span
          onClick={() => {
            if (currentPage !== 1) {
              paginationFunction ? paginationFunction(currentPage - 1, displayEntries) : null;
              setCurrentPage(currentPage - 1);
            }
          }}
          className={`item ${currentPage === 1 ? 'disabled' : ''}`}
        >
          {t('pagination_prev')}
        </span>
        {Array.from(Array(totalPages).keys()).map((singleEntry) => {
          let page = singleEntry + 1;
          if (
            page === 1 ||
            page === 2 ||
            page === currentPage ||
            page === currentPage - 1 ||
            page === currentPage + 1 ||
            page === totalPages ||
            page === totalPages - 1
          ) {
            return (
              <span
                key={page}
                onClick={() => {
                  parseInt(currentPage) !== parseInt(page)
                    ? (paginationFunction ? paginationFunction(page, displayEntries) : null,
                      setCurrentPage(page))
                    : null;
                }}
                className={`item ${parseInt(currentPage) === parseInt(page) ? 'active' : ''}`}
              >
                {page}
              </span>
            );
          } else if (page === 3 || page === totalPages - 2) {
            return (
              <span className='pagination-dots' key={page}>
                ...
              </span>
            );
          }
        })}
        <span
          onClick={() => {
            if (currentPage !== totalPages) {
              paginationFunction ? paginationFunction(currentPage + 1, displayEntries) : null;
              setCurrentPage(currentPage + 1);
            }
          }}
          className={`item ${currentPage === totalPages ? 'disabled' : ''}`}
        >
          {t('pagination_next')}
        </span>
      </div>
      {type !== 'form' && (
        <div className='displayed-entries'>
          {t('pagination_show')}
          <span className={`item`}>
            {totalRecords === 0 ? 0 : (currentPage - 1) * displayEntries + 1}
          </span>
          {t('pagination_to')}
          <span className={`item`}>{(currentPage - 1) * displayEntries + currentPageRecords}</span>
          {t('pagination_entries')}
        </div>
      )}
    </div>
  );
};
Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  displayEntries: PropTypes.number.isRequired,
  currentPageRecords: PropTypes.number,
  type: PropTypes.string,
  paginationFunction: PropTypes.func,
  setDisplayEntries: PropTypes.func,
  setCurrentPage: PropTypes.func,
};
export default Pagination;
