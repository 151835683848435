import React, { useEffect, useState } from 'react';
import i18n from './i18n/config';
import { changeLanguage } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageReducer } from './redux/language';

const LanguageActions = LanguageReducer.actions;

function Language() {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.language);

  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', () => {
    dispatch(LanguageActions.switchLanguage(i18n.language));
    setLocale(i18n.language);
  });

  useEffect(() => {
    changeLanguage(lang);
    document.dir = i18n.dir();
  }, [locale]);

  return (
    <div className={`languageSwitcher`}>
      <span
        onClick={() => {
          changeLanguage('en');
        }}
        className={`${i18n.language === 'en' ? 'active' : ''}`}
      >
        en
      </span>
      <span
        onClick={() => {
          changeLanguage('ar');
        }}
        className={`${i18n.language === 'ar' ? 'active' : ''}`}
      >
        ar
      </span>
    </div>
  );
}

export const isRTL = () => {
  if (i18n.language === 'ar') return true;
  else return false;
};

export default Language;
