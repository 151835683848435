import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import TableUtils from './TableUtils';

const TableData = ({
  data,
  tableFields,
  props,
  loader,
  isActionsEnabled,
  onSelection,
  isSelectionEnabled,
  selectionKey,
  selectedRows,
}) => {
  let { actionsType, actionsCallBack, type, translateValueFunc } = {
    ...props,
  };
  const { t } = useTranslation();

  return (
    <Fragment>
      <tbody>
        {data &&
          data.map((singleRecord, index) => {
            return (
              <tr
                key={index}
                className={`table-checkbox ${
                  type === 'submissions' && actionsCallBack ? 'cursor-pointer' : ''
                }`}
              >
                {isSelectionEnabled ? (
                  <td>
                    <SingleCheckBoxField
                      name={selectionKey.split('.').reduce((acc, curr) => acc[curr], singleRecord)}
                      value={
                        selectedRows
                          .map((e) => (e.id ? e.id : e))
                          .indexOf(
                            selectionKey.split('.').reduce((acc, curr) => acc[curr], singleRecord),
                          ) > -1
                          ? true
                          : false
                      }
                      handleChange={onSelection}
                      text={<></>}
                      classes=''
                    />
                  </td>
                ) : null}
                {tableFields.map((singleHeaderField, index1) => {
                  let value = singleHeaderField.associatedKey;
                  let callbackFunc = singleHeaderField.callbackFunc
                    ? props[singleHeaderField.callbackFunc]
                    : singleHeaderField.sendRowWithKeyValue
                    ? { row: singleHeaderField, key: singleHeaderField.name }
                    : null;
                  return singleHeaderField.isSelected ? (
                    <td key={index1} className='nowrap'>
                      {singleHeaderField.populateFunc
                        ? TableUtils[singleHeaderField.populateFunc](
                            value
                              ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                              : singleRecord,
                            callbackFunc,
                            singleHeaderField.translateVal ? translateValueFunc : null,
                          )
                        : value && singleRecord
                        ? value.split('.').reduce((acc, curr) => acc[curr], singleRecord)
                        : ''}
                    </td>
                  ) : null;
                })}
                {isActionsEnabled && (
                  <td className='d-flex justify-content-end align-items-center'>
                    {TableUtils['actionsList'](singleRecord, actionsType, actionsCallBack)}
                  </td>
                )}
              </tr>
            );
          })}
        {!loader && data && data.length === 0 && (
          <tr>
            <td colSpan={50} className='text-center'>
              {t('text_no_records_found')}
            </td>
          </tr>
        )}
      </tbody>
    </Fragment>
  );
};

TableData.propTypes = {
  selectedRows: PropTypes.array,
  data: PropTypes.array.isRequired,
  tableFields: PropTypes.array.isRequired,
  props: PropTypes.object.isRequired,
  loader: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  isSelectionEnabled: PropTypes.bool,
  selectionKey: PropTypes.string,
  onSelection: PropTypes.func,
};

export default TableData;
