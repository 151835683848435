import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PasswordField = ({
  name,
  placeholder,
  displayName,
  icon,
  value,
  error,
  touched,
  handleChange,
  classes,
  onEnter,
}) => {
  const { t } = useTranslation();
  const [showPass, setShowPass] = useState(false);

  const onEnterKeyPress = (e) => {
    if (e.keyCode === 13) onEnter(e);
  };

  return (
    <div className={`custom-input-box${classes ? ' ' + classes : ''}`}>
      <label htmlFor={name} className={!touched && error ? 'label-error' : ''}>
        {t(displayName)}
      </label>
      <div className='custom-input-field'>
        <input
          type={showPass ? 'text' : 'password'}
          id={name}
          name={name}
          className={!touched && error ? 'input-error' : ''}
          placeholder={t(placeholder)}
          value={value}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          {...(onEnter && { onKeyUp: (e) => onEnterKeyPress(e) })}
        />
        <img src={icon} className='icon' onClick={() => setShowPass(!showPass)} />
      </div>
      {!touched && error && <p className='form-field-error'>{t("Error "+error)}</p>}
    </div>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  onEnter: PropTypes.func,
};

export default PasswordField;
