import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.scss';
import AuthenticatedLayout from './layout/AuthenticatedLayout';
import FirebaseProtectedRoute from './layout/FirebaseProtectedRoute';
import NonAuthenticatedLayout from './layout/NonAuthenticatedLayout';
import Login from './pages/auth-pages/Login';
import i18n from './i18n/config';
import CompaniesList from './pages/companies/CompaniesList.js';

function App() {
  document.dir = i18n.dir();
  return (
    <Fragment>
      <Routes>
        <Route element={<NonAuthenticatedLayout />}>
          <Route path='/login' element={<Login />} />
        </Route>
        {/* <Route path='/about' element={<About />} /> */}

        <Route element={<FirebaseProtectedRoute />}>
          <Route path='/' element={<AuthenticatedLayout />}>
            <Route index element={<CompaniesList />} />
            <Route path='/companies' element={<CompaniesList />} />
            <Route path='*' element={<h1>Not Found</h1>} />
          </Route>
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
