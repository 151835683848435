import React from 'react';
import PropTypes from 'prop-types';

const TabBody = ({ children, transparent, style, variant }) => {
  return (
    <div
      className={`tab-layout-body${transparent ? ' tab-layout-body-transparent' : ''} ${
        variant ? variant : ''
      }`}
      {...(style && { style })}
    >
      {children}
    </div>
  );
};

TabBody.propTypes = {
  children: PropTypes.any.isRequired,
  transparent: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.string,
};

export default TabBody;
