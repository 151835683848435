import React from 'react';
import PropTypes from 'prop-types';
import searchIcon from '../../assets/images/icons/search.svg';
import TextField from '../../components/form-components/TextField';
import CompaniesActions from './CompaniesActions';

const CompaniesListHeader = ({
  selectedTab,
  filteringData,
  setFilteringData,
  selectedRows,
  getAllCompanies,
  debounceFunc,
  isActionAllowed,
}) => {
  return (
    <div className='section-header d-flex justify-content-between px-2'>
      <div className=' d-flex  align-items-center gap-3  '>
        <TextField
          value={filteringData['search'] ? filteringData['search'] : ''}
          name='search'
          handleChange={setFilteringData}
          placeholder='field_search'
          icon={searchIcon}
          shrink
          debounceFunc={debounceFunc}
        />
        <CompaniesActions
          ids={selectedRows}
          isActionButton={false}
          type={selectedTab}
          actionsCallBack={getAllCompanies}
          actionData={{ isActionAllowed }}
        />
      </div>
    </div>
  );
};
CompaniesListHeader.propTypes = {
  selectedTab: PropTypes.string,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  getAllCompanies: PropTypes.func,
  debounceFunc: PropTypes.func,
  selectedRows: PropTypes.array.isRequired,
  isActionAllowed: PropTypes.bool.isRequired,
};
export default CompaniesListHeader;
