import { AuthReducer } from '../redux/auth';
import store from '../store/store';

const AuthAction = AuthReducer.actions;
const dispatch = store.dispatch;

const updateTotalCompanies = (totalCompanies) => {
  dispatch(
    AuthAction.updateTotalCompanies({
      totalCompanies,
    }),
  );
};
const RefreshToken = (data, navigate) => {
  console.log(navigate);
  dispatch(
    AuthAction.updateAuthState({
      authToken: data.access_token,
      refreshToken: data.refresh_token,
    }),
  );
};
const setUserCustomizedTableHeader = (type, data) => {
  console.log(data, type);

  dispatch(
    AuthAction.updateCustomizedTableHeader({
      customizedTableHeader: data,
      type,
    }),
  );
};
export { RefreshToken, updateTotalCompanies, setUserCustomizedTableHeader };
